img {
  width: 100%;
}

pre {
  white-space: pre-wrap;
}

@media print {

    header{
    }

    @page {
        size: 220.5mm 313.0mm;
        margin: 150px 75px 37px 135px ;
        font-family: 'borges', sans-serif;
        font-size: 24px;
    }
    @page :right {
        @top-right-corner {
            font-size:1.5rem;
            content: "D";
            font-family: 'FFBauWebProMedium', sans-serif;
            text-align: center;
        }



        @top-left-corner {
            font-size:1.5rem;
            content: counter(page);
            font-family: 'FFBauWebProMedium', sans-serif;
            text-align: center;
        }


        @top-right {
            font-size:1rem;
            content: element(address_name);
            text-align: left;
            font-family: 'Glovis NP';
            margin-left: 16em;
            margin-top: 10px;
            vertical-align:top;
        }

        /* latéral */
        @right-top {
            content: string(authors);
            text-align: left;
            font-size:1rem;
            margin-right:10px;
            margin-top:30mm;
            font-family: 'Glovis NP';
            writing-mode: vertical-rl;
            text-orientation: mixed;
        }

    }
    @page :left {
        @top-left-corner {
            font-size:1.5rem;
            content: "N";
            font-family: 'FFBauWebProMedium', sans-serif;
            text-align: center;
        }

        @top-right-corner {
            font-size:1.5rem;
            content: counter(page);
            font-family: 'FFBauWebProMedium', sans-serif;
            text-align: center;
        }

        @top-right {
            font-size:1rem;
            font-family: 'Glovis NP';
            content: element(person_name);
            text-align: left;
            margin-left: 16em;
            margin-top: 10px;
            vertical-align:top;
        }

        /* latéral */
        @left-top {
            content: string(authors);
            text-align: left;
            font-size:1rem;
            margin-left:20px;
            margin-top:30mm;
            font-family: 'Glovis NP', monospace;
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            text-orientation: mixed;
            height:80mm;
        }
    }

    @page {
        /* latéral */

    }

    h1 .right{
        position: running(person_name);
    }

    h1 .left{
        position: running(address_name);
    }

    .authors{
        display: none;
        string-set: authors content(text);
    }

    .portrait{
        position: relative;
        transform: rotate(3deg);
        float: left;
        width: 50mm;
        margin-top: 1mm;
        margin-left: -40mm;
        margin-right: 4mm;
        margin-bottom: -1mm;
    }

    .note-text{
        position:relative;
        width:20mm;
        margin-top:-10mm;
        margin-left:-22mm;
        transform: rotate(3deg);
        float:left;
        font-size:.5em;
        line-height:1em;
        font-style: normal;
        font-family: 'FFBauWebProMedium', sans-serif;
    }
    .note-text .nb{
        position: absolute;
        left:-2em;
    }
    .note-text span[lang]{
        color:#007783;
    }

}

.step p, .step h3, .person{
    display: inline;
    line-height:1em;
    font-size:1em;
}

.step h3{
    display: inline;
    margin: 0 0 1em 0;
    vertical-align: top;
    font-family: 'FFBauWebProMedium', sans-serif;
}

.person{
    font-family: 'FFBauWebProMedium', sans-serif;
    font-weight:bold;
    font-size:.9em;
    display: inline-block;
}
.step p:first-of-type .person:first-child{
    margin: .5em 0 0 0;
}
.interviewer .person span{
    text-decoration: underline;
}
p.interviewer{
    font-style:italic;
}
p.interviewer .person{
    font-style:normal;
}
img.portrait{
    display: block;
}